import React, { ReactComponent } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';

import BendableLabsLogo from '../components/footer/BendableLabsLogo';

import {
  maineDigest,
  heroImage,
  columnWrapper,
  headingLogo,
  headingWrapper,
  heading,
  instance,
  subheading,
  dateline,
  textWrapper,
  whatsNew,
  colWrapper,
  leftCol,
  rightCol,
  photoCaption
} from './maine_digest_2022_07.module.scss';

import BendableLogo from '../svg/Bendable_Logo.svg';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const MaineDigestPage = () => {
  return (
    <Layout pageTitle="Bendable Maine">

      <div id="page" className="Site" >
        <a className="skip-link screen-reader-text" href="#primary">Skip to content</a>

        <header id="masthead" className="Site-header">
          <div className="Site-branding">
            <a href="/" className="custom-logo-link" rel="home"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>		</div>
          
            <div className="Community-header">
              <div className="Community-name">Maine</div>  			<div className="back">
                <Link to="/maine">
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15 7.3132C15 7.56181 14.9012 7.80024 14.7254 7.97603C14.5496 8.15183 14.3112 8.25059 14.0626 8.25059H3.20194L7.22712 12.2739C7.31427 12.361 7.38341 12.4645 7.43058 12.5784C7.47775 12.6923 7.50202 12.8143 7.50202 12.9376C7.50202 13.0608 7.47775 13.1829 7.43058 13.2967C7.38341 13.4106 7.31427 13.5141 7.22712 13.6012C7.13996 13.6884 7.0365 13.7575 6.92262 13.8047C6.80875 13.8519 6.6867 13.8761 6.56344 13.8761C6.44019 13.8761 6.31814 13.8519 6.20426 13.8047C6.09039 13.7575 5.98692 13.6884 5.89977 13.6012L0.275395 7.97687C0.188099 7.8898 0.118838 7.78635 0.0715815 7.67247C0.0243247 7.55858 0 7.4365 0 7.3132C0 7.1899 0.0243247 7.06781 0.0715815 6.95392C0.118838 6.84004 0.188099 6.7366 0.275395 6.64952L5.89977 1.02515C6.07578 0.84913 6.31452 0.750244 6.56344 0.750244C6.81237 0.750244 7.0511 0.84913 7.22712 1.02515C7.40314 1.20117 7.50202 1.4399 7.50202 1.68882C7.50202 1.93775 7.40314 2.17648 7.22712 2.3525L3.20194 6.3758H14.0626C14.3112 6.3758 14.5496 6.47456 14.7254 6.65036C14.9012 6.82615 15 7.06458 15 7.3132Z" fill="#A8A8A8"/>
                  </svg>
                  &nbsp;Back
                </Link>
              </div>
            </div>
          
        </header>

        <main id="primary" className="Site-main">
          <div className={maineDigest}>
            <article id="post-41" className="post-41 communities type-communities status-publish has-post-thumbnail hentry">
              <div>&nbsp;</div>
              <div className={columnWrapper}>
                <div>
                  <div className={headingLogo}>
                    <BendableLogo style={{ height: '100%' }} />
                  </div>
                  <div className={headingWrapper}>
                    <div className={heading}>Bendable <span className={instance}>Maine</span> Digest</div>
                    <div className={subheading}>A monthly update on the lifelong learning system being shaped by Mainers for Mainers</div>
                    <div className={dateline}>July 2022</div>
                  </div>
                </div>

                
                <div className={textWrapper}>

                  <div className={whatsNew}>What's New?</div>

                  <p>
                  As we’ve noted many times before, when Bendable Maine launches next
                  month—Aug. 23 is the date!—the lifelong learning system will include content
                  on a wide range of topics. For many Mainers, though, one area in particular is
                  sure to feel most urgent: learning for work.
                  </p>
                  <p>
                  This is why Bendable will feature a set of Career Collections: learning
                  pathways that will help provide residents with a basic foundation in a given
                  field and, in turn, give them a leg up when they’re looking for a job.
                  </p>
                  <p>
                  To put together these Career Collections, the Bendable Maine team has
                  reviewed labor market data, studied
                  the state’s <a href="https://www.maine.gov/decd/sites/maine.gov.decd/files/inline-files/DECD_120919_sm.pdf" target="_blank" rel="noreferrer">10-year Economic
                  Development Strategy</a> and sought
                  the input of local employers. Our
                  aim is to ensure that the skills being
                  taught on Bendable match up with
                  what business really needs.
                  </p>

                  <p>
                  For example, Kittery Trading Post
                  has worked with us on a Career
                  Collection titled “Getting started as
                  a retail sales associate.” IDEXX,
                  which makes diagnostic and
                  software products for the world of
                  veterinary medicine, has assisted us with “Getting started as a production
                  technician.” And Portland-based Project&gt;Login has helped us with “Getting
                  started as a technical support specialist.”
                  </p>

                  <div className={colWrapper}>
                    <div className={leftCol}>
                      <StaticImage src="https://bendable.s3.us-west-1.amazonaws.com/bmd_idexx.jpg" alt="IDEXX Headquarters" />
                      <div className={photoCaption}>
                      Westbrook-based IDEXX is among the
                      companies collaborating with Bendable.
                      </div>
                    </div>

                    <div className={rightCol}>
                      <p>
                      And there is much more to come. We’re also in discussion with the Maine
                      Community College System so that the digital badges earned in a Bendable
                      Career Collection will connect seamlessly to the college’s own emerging
                      workforce development pathways.
                      </p>
                    </div>

                  </div>

                </div>

              </div>
            </article>
          </div>
        </main>

        <footer id="colophon" className="Site-footer">
          <div className="Site-info">
            <div className="Site-logos">
              <a href="https://www.maine.gov/msl/" target="_blank" rel="noreferrer">
                <img width="146" height="146" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-State-Library-Logo.png" className="attachment-full size-full" alt="" loading="lazy" />
              </a>

              <div style={{ width: '60px', height: '30px' }} />
              
              <BendableLabsLogo
                width = {{ xs: '150px', sm: '200px', md: '200px', lg: '200px', xl: '250px' }}
              />

            </div> 		
            
          </div>
          
          {/* <nav id="site-navigation" className="Site-navigation">
            <div className="menu-menu-container">
              <ul id="footer-menu" className="menu"><li id="menu-item-200" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-200"><a href="https://drucker.institute/programs/city-of-lifelong-learning/">Network</a></li>
                <li id="menu-item-201" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-201"><a href="mailto:support@bendable.com">Tech Support</a></li>
                <li id="menu-item-202" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-202"><a href="https://network.bendable.com/privacy-policy">Privacy Policy</a></li>
                <li id="menu-item-203" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-203"><a href="https://network.bendable.com/terms-of-service">Terms of Service</a></li>
              </ul>
            </div>
          </nav> */}
          
        </footer>
      </div>

      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/navigation.js?ver=1.0.0' id='bendable-navigation-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/flickity.js?ver=2.2' id='flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/mobile-flickity-slider.js?ver=2.2' id='custom-flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/wp-embed.min.js?ver=5.7.2' id='wp-embed-js'></script>
    </Layout>
  )
}

export default MaineDigestPage;
// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2022_07-module--maineDigest--0nRPu";
export var heroImage = "maine_digest_2022_07-module--heroImage--hyqCT";
export var columnWrapper = "maine_digest_2022_07-module--columnWrapper--QEJ1C";
export var headingLogo = "maine_digest_2022_07-module--headingLogo--tGmN2";
export var headingWrapper = "maine_digest_2022_07-module--headingWrapper--E0Tb8";
export var heading = "maine_digest_2022_07-module--heading--ZxGbH";
export var instance = "maine_digest_2022_07-module--instance--C37Ax";
export var subheading = "maine_digest_2022_07-module--subheading--PUuHs";
export var textWrapper = "maine_digest_2022_07-module--textWrapper--WMSU-";
export var latestnewsarticleheadline = "maine_digest_2022_07-module--latestnewsarticleheadline--z8XUt";
export var dateline = "maine_digest_2022_07-module--dateline--XU98a";
export var whatsNew = "maine_digest_2022_07-module--whatsNew--hz-oS";
export var colWrapper = "maine_digest_2022_07-module--colWrapper--NxN-e";
export var leftCol = "maine_digest_2022_07-module--leftCol--a8U8N";
export var rightCol = "maine_digest_2022_07-module--rightCol--fUn7P";
export var photoCaption = "maine_digest_2022_07-module--photoCaption--hoKBz";
export var afterCaption = "maine_digest_2022_07-module--afterCaption--RP3Sa";